@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

html, body 
{ 
height: 100%;
overflow-x: hidden;
}

.__home__paginaInicial {
  position: absolute;
  width: 100%;
  z-index: 99999;
  overflow-x: hidden;
}

.__home__cabecalho {
  background:rgba(1, 25, 12);
}

.__home__conteudoPrincipal {
  width: 100%;
}

.__home__divisaoApresentacao {
  margin: 1rem;
}

.__home__apresentacaoTitulo {
  font-weight: bold;
  color: #ffffff;
  font-size: 2rem;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  margin: 1rem 0 0 0;
}

.__home__apresentacaoSubtitulo {
  width: 25rem;
  font-size: 1.1rem;
  color: #ffffff;
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  margin: 2rem 0 0 0;
}

.__home__divisaoAbraSuaConta {
  margin: 1rem;
  border: 3px solid #ffffff;
  color: #ffffff;
  text-align: center;
  background: rgba(1, 24, 12, 0.616);
  max-width: 25rem;
  padding: 2rem 1.5rem 2rem 1.5rem;
  border-radius: 7.5px;
}

.__home__AbraSuaContaTitulo {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  margin: .75rem 0 0 0;
}

.__home__AbraSuaContaSubtitulo {
  font-size: 1.15rem;
  margin: 1rem 0;
}

.__home__divisaoAbraSuaContaInputs {
  display: flex;
  flex-direction: column;
}

.__home__AbraSuaContaInput {
  color: #ffffff;
  border: 1px solid #fff;
  background: transparent;
  margin: .5rem 0 .5rem 0;
  border-radius: 3px;
  padding: .75rem .25rem .75rem .5rem;
  font-size: 1rem;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.__home__AbraSuaContaInput::placeholder {
  color: #ffffff;
  opacity: 1;
  font-size: .75rem;
}

.__home__BtnAbraSuaConta {
  background: transparent;
  font-weight: bold;
  color: #ffffff;
  border-radius: 3px;
  padding: .75rem 2.5rem;
  cursor: pointer;
  margin: 1rem auto;
  border: 1px solid #ffffff;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.__home__BtnAbraSuaConta:hover {
  background: #ffffff;
  color: #056065;
}

.__home__AbraSuaContaTextoPolitica {
  font-size: .75rem;
}

.__home__AbraSuaContaPoliticaPrivacidade {
  font-size: .75rem;
  color: #fba300;
}

.__home__AbraSuaContaPoliticaPrivacidade:hover {
  text-decoration: underline;
}

.__home__AbraSuaContaInput:focus {
  outline: none;
  border-radius: 3px;
  box-shadow: #fba30096 0px 0px 0px 3px;
}

.__home__divisaoHeader {
  height: 85.59px;
} 

.__home__divisaoFooter {
  display: none;
  height: 85.59px;
}

.__home__divisaoCabecalho {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  background: rgb(26, 24, 24);
}

.__home__logoNorway {
  width: 12rem;
}

.__home__navegacao {
  display: flex;
  align-items: center;
}

.__home__itens {
  display: flex;
  margin: 0 0 0 2rem;
}

.__home__item {
  margin: 0 .5rem;
}

.__home__link {
  cursor: pointer;
  color: #fff;
  font-size: 12px;
}

.__home__link:hover {
  border-bottom: 3px solid #FFFFFF;
  padding: 0 0 1.75rem 0;
}

.__home__iconeNavegacao {
  transition: all 400ms cubic-bezier(.23, 1, 0.32, 1);
  margin: 0 0 0 .5rem;
}

.__home__btn {
  border: none;
  padding: .65rem 1.15rem;
  border-radius: 1.5rem;
  cursor: pointer;
  margin: .5rem;
  font-family: 'Montserrat', sans-serif;
}

.__home__btnAcessar {
  background: #FBA500;
  color: #FFFFFF;
  font-weight: bold;
}

.__home__btnAcessar:hover {
  background: #fba300de;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.__home__btnAbrir {
  position: relative;
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #FFFFFF;
  font-weight: 600;
}

.__home__btnAbrir:hover {
  color: #000000;
  background: #FFFFFF;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.__home__inicialNorway {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-image: url(../assets/images/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.__home__divisaoInicial {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.__home__tituloInicial {
  color: #FBA500;
  font-weight: bold;
  font-size: 40px;
  width: 30rem;
  text-align: center;
}

.__home__subtituloInicial {
  margin: 1rem 0 1rem 0;
  color: #FFFFFF;
  font-weight: 300;
}

.__home__btnMais {
  background: #FBA500;
  color: #FFFFFF;
  font-weight: bold;
  margin: 0;
}

.__home__btnMais:hover {
  background: #fba300de;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.__home__cards {
  margin: 10rem 0 0 0;
}

.__home__divisaoCards {
  display: flex;
  text-align: center;
  justify-content: center;
}

.__home__card {
  background: rgba(11, 75, 36, 0.4);
  border-radius: .5rem;
  padding: 1rem;
  margin: 1rem;
  width: 18rem;
  height: 21rem;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.__home__linha {
  background: #FBA500;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  margin: 1rem 0 2rem 0;
}

.__home__card1 {
  margin: 1.5rem;
}

.__home__divisaoMenu {
  display: flex;
  align-items: center;
}

.__home__divisaoIconeMenu {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  background: #FBA500;
  color: #FFFFFF;
  margin: 0 .5rem 0 1rem;
}

.__home__dropMenu {
  background: rgb(26, 24, 24);
  margin: 1.90rem 0 0 0;
  padding: 1rem 0 0 0;
  border-radius: .25rem;
  position: absolute;
  left: 0;
  width: 100%;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.__home__IconeMenu {
  margin: .39rem 0 0 0;
}

.__home__linhaMenu {
  width: 100%;
  height: 1px;
  margin: .25rem 0 .25rem 0;
}

.__home__linkDrop {
  color: #FFFFFF;
  font-size: 12px;
}

.__home__itemDrop:hover>.__home__linkDrop {
  color: #FBA500;
}

.__home__itemDrop {
  padding: .5rem .5rem .5rem .5rem;
  margin: .25rem 1rem .65rem 1rem;
  width: 100%;
  cursor: pointer;
  transition: all 100ms cubic-bezier(.23, 1, 0.32, 1);
  border-left: 3px solid transparent;
}

.__home__tituloMenu {
  color: #FFFFFF;
  font-weight: 600;
}

.__home__itemDrop:hover {
  border-radius: 2px 0 0 2px;
  border-left: 3px solid #FBA500;
}

.__home__item:hover>.__home__link>.__home__iconeNavegacao {
  transform: rotate(180deg);
}

.__home__quemSomos {
  color: #FFFFFF;
}

.__home__tituloQuemSomos {
  font-size: 35px;
  font-weight: bold;
}

.__home__quemSomos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 8rem 0 5rem 0;
}

.__home__subtituloQuemSomos {
  font-size: 25px;
  margin: 1rem 0 0 0;
}

.__home__subtituloQuemSomos::after {
  content: " NorwayBank";
  font-weight: bold;
}

.__home__explicacaoQuemSomos {
  font-weight: 300;
  width: 500px;
  margin: 1rem 0 0 0;
}

.__home__videoSobre {
  max-width: 100%;
  border-radius: .5rem;
  width: 600px;
}

.__home__divisaoSobre {
  margin: 0 3rem 0 0;
}

.drop_duplo {
  display: flex !important;
}

.__home__menuLateral {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #FFFFFF;
  font-size: 22.5px;
  cursor: pointer;
  z-index: 999;
  display: none;
}

.open {
  display: block;
}

.openDuplo {
  display: flex;
}

.__home__divisaoTipoPessoa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgb(26, 24, 24);
  padding: .5rem;
  border-radius: 5px;
  width: 200px;
  position: absolute;
  top: 2.85rem;
  left: -.7rem;
}

.__home__tipoPessoa {
  color: #fff;
  margin: .15rem .25rem .15rem .25rem;
  font-size: .75rem;
  padding: .5rem;
  border-radius: 3px;
  width: 100%;
  text-align: start;
  border-left: 3px solid transparent;
}

.__home__tipoPessoa:hover {
  border-left: 3px solid #FBA500;
  color: #FBA500;
}

.__home_section_principal {
  display: flex;
  flex-direction: column;
  margin: 0 10% 0 auto;
  align-items: flex-start;
}

.__home_section_principal_imagem {
  width: 20rem;
}

.__home_section_principal_titulo {
  font-size: 3rem;
}

.__home_section_principal_subtitulo {
  font-size: 2.5rem;
}

.__home_section_principal_botao {
  border: none;
  cursor: pointer;
  margin: 1rem 0 0 0;
  padding: .5rem 1rem;
  border-radius: .5rem;
  color: #fff;
  background: #fba500;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 960px) {
  .__home__divisaoCards {
      flex-wrap: wrap;
  }
}

@media screen and (max-width: 880px) {
  .__home__inicialNorway {
    flex-direction: column;
  }

  .__home__apresentacaoLogo {
    display: none;
  }
}

@media screen and (max-width: 1200px) {

  .__home__link:hover {
    color: #FBA500;
    text-decoration: underline;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  }
  
.drop_duplo {
  display: flex !important;
  flex-direction: column;
}

  .__home__divisaoFooter {
    display: block;
  }

  .__home__divisaoTipoPessoa {
    top: -6rem;
  }

  .__home__menuLateral {
      display: block;
  }

  .__home__divisaoBtnMenu {
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    display: flex !important;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 1rem;
    background: rgb(26, 24, 24);
    margin: 0 0 0 -.8rem;
    border-top: 1px solid #FBA500;
  }

  .__home__btn { 
    display: block !important;
  }

  .__home__btnAcessarBanco {
      display: none;
  }

  .__home__link {
      font-size: 15px;
  }

  .__home__dropMenu {
      position: relative;
      margin: 0;
      text-align: start;
  }

  .open {
      display: block;
  }

  .openDuplo {
      display: block;
  }

  .__home__item {
      padding: 1rem 0 0 2rem;
  }

  .__home__itens {
      position: absolute;
      background: rgb(26, 24, 24);
      width: 400px;
      height: 100vh;
      top: 0;
      right: -100vh;
      padding: 1rem 0 0 0;
      text-align: start;
      display: block;
      z-index: 998;
      overflow-y: auto;
      overflow-x: hidden;
      transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  }

  .__home__itens.menu_open {
      right: 0;
  }

  .item_drop {
      margin: .15px;
  }

  .__home__link:hover {
      border-bottom: none;
      padding: 0;
  }
}

@media screen and (max-width: 700px) {
  .__home__divisaoSobre {
      text-align: center;
  }

  .__home__videoSobre {
      width: 300px;
      margin: 2rem 0;
  }

  .__home__explicacaoQuemSomos {
      width: 300px;
  }

  .__home__divisaoSobre {
      margin: 0;
  }

  .__home__tituloInicial {
      text-align: center;
      width: 300px;
  }
}

@media screen and (max-width: 420px) {
  .__home__itens {
    width: 350px;
  }
}

@media screen and (max-width: 450px) {
  .__home__divisaoApresentacao {
    text-align: center;
  }

  .__home__apresentacaoLogo {
    width: 15rem;
  }

  .__home__apresentacaoTitulo {
    font-size: 1.5rem;
  }

  .__home__apresentacaoSubtitulo {
    font-size: 1rem;
  }
  
  .__home__inicialNorway {
    justify-content: initial;
  }
}

@media screen and (max-width: 394px) {
  .__home__divisaoTipoPessoa {
    padding: .5rem;
    border-radius: 5px;
    position: absolute;
    top: -5.85rem;
    left: .38rem;
    width: 10rem;
  }
}

@media screen and (max-width: 345px) {
  .__home__divisaoTipoPessoa {
    padding: .5rem;
    border-radius: 5px;
    position: absolute;
    top: -5.85rem;
    left: -.5rem;
    width: 10rem;
  }
}
