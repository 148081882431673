@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

html, body 
{ 
height: 100%;
overflow-x: hidden
}

.__ajuda__atendimentos {
    position: absolute;
    width: 100%;
    z-index: 99999;
    color: #FFFFFF;
}

.__ajuda__divisaoAtendimentos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.__ajuda__divisaoAtendimento {
    padding: 2rem 1.5rem;
    width: 22rem;
    height: 28.5rem;
    background: rgb(26, 24, 24);
    margin: 1.5rem;
    border-radius: .35rem;
}

.__ajuda__tituloAtendimentos {
    margin: 8rem 0 1.25rem 0;
    color: #FFFFFF;
    text-align: center;
}

.__ajuda__divisaoIcone {
    background: #FBA500;
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 .75rem 0 0;
}

.__ajuda__divisaoTitulo {
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
}

.__home__divisaoFooter {
    display: none;
    height: 85.59px;
  }

.__ajuda__iconeAtendimento {
    font-size: 25px;
    margin: .78rem 0 0 0;   
}

.__ajuda__subtituloAtendimento {
    font-size: 14px;
    font-weight: bold;
}

.__ajuda__contato {
    font-size: 14px;
}

.__ajuda__tituloContato {
    font-size: 14px;
    margin: 1rem 0 .5rem 0;
}

.__ajuda__sobreTitulo {
    width: 300px;
    font-weight: 600;
    font-size: 13px;
    color: #FBA500;
}

.__ajuda__contato {
    font-weight: bold;
    font-size: 14px;
}

.__ajuda__contatoDia {
    width: 10px;
}

@media screen and (max-width: 1200px) {
    .__home__divisaoFooter {
        display: block;
    }
}